import { Redirect, Route } from "react-router-dom";
import React, { Suspense } from "react";

import {
  IonApp,
  setupIonicReact,
  IonSpinner,
  IonRouterOutlet,
  IonSplitPane,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Login from "./pages/Login";
import NotFoundPage from "./pages/NotFoundPage";
import Menu from "./components/Menu";
import Tab1 from "./pages/Tab1";
import Tab2 from "./pages/Tab2";
import Tab3 from "./pages/Tab3";

import HomePage from "./pages/Home";
import LogOutPage from "./pages/LogOutPage";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { withTranslation } from "react-i18next";

setupIonicReact();

const App: React.FC = () => {
  const user: string = JSON.parse(localStorage.getItem("user")!);
  console.log(user);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <IonSpinner></IonSpinner>
        </div>
      }
    >
      <IonApp>
        <>
          {user ? (
            <IonReactRouter>
              <IonSplitPane contentId="main">
                <Menu />
                <IonRouterOutlet id="main">
                  <Route exact path="/page/home">
                    <HomePage />
                  </Route>

                  <Route path="/home/tab1">
                    <Tab1 />
                  </Route>

                  <Route path="/home/tab2">
                    <Tab2 />
                  </Route>

                  <Route path="/home/order/:id/edit">
                    <Tab3 />
                  </Route>

                  <Route exact path="/login">
                    <Redirect to="/home" />
                  </Route>
                  <Route exact path="/">
                    <Redirect to="/home" />
                  </Route>
                  <Route exact path="/logout">
                    <LogOutPage />
                  </Route>
                  <Route>
                    <NotFoundPage />
                  </Route>
                </IonRouterOutlet>
              </IonSplitPane>
            </IonReactRouter>
          ) : (
            <IonReactRouter>
              <Route exact path="/login">
                <Login />
              </Route>
              <Redirect from="/" to="/login" exact />
            </IonReactRouter>
          )}
        </>
      </IonApp>
    </Suspense>
  );
};

export default withTranslation()(App);
