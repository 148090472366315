import React from 'react';
import {
  IonButton, IonCol,
  IonContent, IonGrid, IonIcon, IonPage,
  IonRow,
  IonLabel, IonList, IonInput, IonItem, IonImg
} from '@ionic/react';
import styles from './Login.css';
import { logIn as logInIcon, eye as eyeIcon, eyeOff as eyeOffIcon } from "ionicons/icons";
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import axios from '../axios';
import { useAuth } from '../contexts/AuthContext';


const Login = () => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const routeRedirect = document.createElement('ion-route-redirect');
  routeRedirect.setAttribute('from', '/login');
  routeRedirect.setAttribute('to', '/page/anuncios');

  const { setUser } = useAuth();
  const { user } = useAuth();

  // login user
  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      user: email,
      password: password,
    };
    try {
      const resp = await axios.post('/login', body);
      if (resp.status === 200) {
        setUser(resp.data.user);
        console.log(resp);
        localStorage.setItem('user', JSON.stringify(resp.data.user));
        history.push('/home/tab1');
        window.location.reload();
        return <Redirect to="/home/tab1" />;
      }
    } catch (error) {
      if (error.response.status === 401) {
        console.log(error.response.data.message);
      }
    }
  };


  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };


  if (user) {
    return <Redirect to="/home" />;
  }

  return (
    <IonPage className={styles.loginPage}>

      <IonContent fullscreen>
        <IonGrid className="ion-padding">
          <IonRow>
            <IonCol style={{ border: '0px' }}>
              <div className="center">
                <IonImg style={{
                  width: '20%',
                  margin: 'auto',
                  display: 'block', minWidth: '200px'
                }}
                  alt="Silhouette of a person's head"
                  src="/assets/images/logo.png" />
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12" className={styles.headingText} style={{ border: '0px' }}>
              <div className="ion-text-center">
              </div>
            </IonCol>
          </IonRow>

          <IonRow className="ion-margin-top ion-padding-top">
            <IonCol size="12" style={{ border: '0px' }}>

              <IonList>
                <IonItem>
                  <IonLabel color="success" position="stacked">Usuario</IonLabel>
                  <IonInput type="text" value={email} onKeyDown={handleKeyDown}
                    onIonChange={(event) => setEmail(event.detail.value)}
                  />
                </IonItem>
                <IonItem>
                  <IonLabel color="success" position="stacked">Contraseña</IonLabel>
                  <IonInput type={showPassword ? "text" : "password"} value={password}
                    onIonChange={(event) => setPassword(event.detail.value)} onKeyDown={handleKeyDown}
                  />
                  <IonIcon onClick={(event) => setShowPassword(!showPassword)}
                    icon={showPassword ? eyeIcon : eyeOffIcon} slot="end" />
                </IonItem>
              </IonList>



              <IonButton color="success" className="custom-button" expand="block" fill="solid"
                onClick={handleSubmit}>
                <IonIcon icon={logInIcon} slot="end" />
                Iniciar sesión
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      {/*<IonFooter>
				<IonGrid className="ion-no-margin ion-no-padding">

                    <Action message="Don't have an account?" text="Sign up" link="/signup" />
                    <Wave />
				</IonGrid>
                        </IonFooter>*/}
    </IonPage>
  );
};

export default Login;