import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Redirect, useHistory } from "react-router-dom";
import authService from "../services/auth.service";

const LogOutPage: React.FC = () => {
  authService.logout();
  const history = useHistory();
  setTimeout(() => {
    //document.body.classList.remove('login-bg');
    history.push("/login");
    window.location.reload();
  }, 300);
  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Tab 1</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Redirect to="/" />
      </IonContent>
    </IonPage>
  );
};

export default LogOutPage;
