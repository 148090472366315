import { useState } from "react";
import {
	IonButton, IonCardTitle, IonCol, IonContent, IonFooter, IonGrid, IonHeader,
	IonPage, IonRouterLink, IonRow, IonToolbar, IonButtons, IonMenuButton,
	IonTitle, IonIcon, IonList, IonItem, IonLabel, IonInput
} from '@ionic/react';
import { Action } from '../components/Action';
import {
	add as addIcon, send as sendIcon
} from "ionicons/icons";

const Home = () => {

	const [name, setName] = useState('');
	
	
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons>
						<IonMenuButton />
						<IonTitle>{'Home'}</IonTitle>
						<IonButton slot="end" fill="clear" routerLink={"/page/home/new"}>
							<IonIcon icon={addIcon} slot="icon-only" />
						</IonButton>
					</IonButtons>



				</IonToolbar>

			</IonHeader>
			<IonContent fullscreen>

				<IonList>
					<IonItem>
						<IonLabel position="floating">Name</IonLabel>
						<IonInput
							value={name}
							onIonChange={(e) => setName(e.detail.value)}
						></IonInput>
					</IonItem>

					<IonButton
						fill="outline"
						expand="block"
						onClick={() => {
							console.log('');
						}}
					>
						<IonIcon icon={sendIcon} slot="end"></IonIcon>
						Crear
					</IonButton>
				</IonList>


				<IonGrid>
					<IonRow className={`ion-text-center ion-justify-content-center`}>
						<IonCol size="11">
							<IonCardTitle>Join millions of other people discovering their creative side</IonCardTitle>
						</IonCol>
					</IonRow>

					<IonRow className={`ion-text-center ion-justify-content-center`}>
						<IonRouterLink routerLink="/signup" className="custom-link">
							<IonCol size="11">
								<IonButton className={`custom-button`}>Get started &rarr;</IonButton>
							</IonCol>
						</IonRouterLink>
					</IonRow>
				</IonGrid>
			</IonContent>

			<IonFooter>
				<IonGrid>
					<Action message="Already got an account?" text="Login" link="/login" />
				</IonGrid>
			</IonFooter>
		</IonPage>
	);
};

export default Home;