import React, { useState, useRef, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonMenuButton,
  IonToolbar,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonIcon,
  IonFab,
  IonFabButton,
  useIonLoading,
  IonToast,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonBackButton,
} from "@ionic/react";
import {
  addCircle as addCircleIcon,
  add as addIcon,
  send as sendIcon,
} from "ionicons/icons";

import axios from "../axios";
import { useAuth } from "../contexts/AuthContext";
import AppTypeahead from "./AppTypeahead";
import AppTypeaheadArticulos from "./AppTypeaheadArticulos";
import { useParams } from "react-router-dom";

interface Params {
  id: string; // Assuming 'id' is a string parameter
}

const Tab3: React.FC = () => {
  const { id } = useParams<Params>();
  console.log("Tab3", id);
  const { csrfToken } = useAuth();
  const user: string = JSON.parse(localStorage.getItem("user")!);
  const [loadig, setLoading] = React.useState(true);
  const [value, setValue] = React.useState("");

  const [selectedFruitsText, setSelectedFruitsText] = useState<string>(
    "Selecione un cliente"
  );
  const [selectedArticulosText, setSelectedArticulosText] = useState<string>(
    "Selecione un Articulo"
  );
  const [selectedFruits, setSelectedFruits] = useState<string[]>([]);
  const [selectedArticulos, setSelectedArticulos] = useState<string[]>([]);
  const [clientes, setClientes] = useState<any[]>([]);
  const [cliente, setCliente] = useState<any[]>([]);
  const [articulo, setArticulo] = useState<any[]>([]);
  const [currentArticuloCosto, setCurrentArticuloCosto] = useState<string>();
  const [cantidad, setCantidad] = useState<string>("1");
  const [total, setTotal] = useState<number>(0);

  const [orden, setOrden] = useState<any[]>([]);
  const [medidas, setMedidas] = useState<any[]>([]);
  const [present, dismiss] = useIonLoading();
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState<string>();
  const [colorMessage, setColorMegessa] = useState<string>();
  const [observaciones, setObservaciones] = useState<string>();
  const [solicitante, setSolicitante] = useState<string>();
  const [medida, setMedida] = useState<any>();

  //let clientes : Item[] = [];

  const modal = useRef<HTMLIonModalElement>(null);
  const modalArticulos = useRef<HTMLIonModalElement>(null);
  const modalDate = useRef<HTMLIonModalElement>(null);

  const [selectedDate, setSelectedDate] = useState(new Date().toISOString());

  function confirm() {
    modalDate.current?.dismiss();
  }

  const handleDateChange = (event: any) => {
    setSelectedDate(event.detail.value);
    modalDate.current?.dismiss();
  };

  const formatData = (data: any[]) => {
    if (data.length === 1) {
      //const fruit = clientes.find(clientes => clientes.value === data[0])!;
      setCliente(data[0]);
      return data[0].nombre;
    }

    return `${data.length} Cliente`;
  };

  const formatDataArticulos = (data: any[]) => {
    if (data.length === 1) {
      //const fruit = clientes.find(clientes => clientes.value === data[0])!;
      setMedida(null);
      setArticulo(data[0]);
      setCurrentArticuloCosto(data[0].precio);
      console.log(data[0]);
      if (data[0].medida) {
        const found = medidas.find((obj) => {
          return obj.id === data[0].medida;
        });

        console.log(found);
        setMedida(found);
        setValue(found.id);
      }
      return data[0].articulo;
    }

    return `${data.length} articulos`;
  };

  const handleChangeTipoVenta = (value: number) => {
    setMedida(null);
    const found = medidas.find((obj) => {
      return obj.id === value;
    });
    setMedida(found);
  };

  const fruitSelectionChanged = (fruits: string[]) => {
    setSelectedFruits(fruits);
    setSelectedFruitsText(formatData(fruits));
    modal.current?.dismiss();
  };

  const articuloSelectionChanged = (articulos: string[]) => {
    setSelectedArticulos(articulos);
    setSelectedArticulosText(formatDataArticulos(articulos));
    modalArticulos.current?.dismiss();
  };

  const handleSubmit = async () => {
    const body = {};
    await csrfToken();
    try {
      const resp = await axios.get("/order/edit/" + id, body);

      if (resp.status === 200) {
        //console.log(resp.data);
        dismiss();
        setClientes(resp.data.clientes);
        setMedidas(resp.data.medidas);
        setOrden([
          ...orden,
          ...resp.data.orden.detalles
        ]);
        setSolicitante(resp.data.orden.solicita);
        setObservaciones(resp.data.orden.observaciones);
        setCliente(resp.data.orden.cliente_id);
        setSelectedFruits(resp.data.orden.cliente);
        setSelectedFruitsText(resp.data.orden.cliente.nombre);

        setLoading(false);
        console.log('handleSubmit', resp.data.orden.detalles);
      }
    } catch (error) {
      dismiss();
      console.log(error);
    }
  };

  const addItem = () => {
    if (articulo) {
      setOrden([
        ...orden,
        {
          articulo: articulo,
          precio: currentArticuloCosto,
          cantidad: parseFloat(cantidad),
          subtotal: parseFloat(currentArticuloCosto!) * parseFloat(cantidad),
          medida: medida.id,
        },
      ]);
      clearInputs();
    }
  };

  const removeFromOrder = (index: number) => {
    setOrden((oldValues) => {
      return oldValues.filter((_, i) => i !== index);
    });
    console.log(index);
  };

  const clearInputs = () => {
    //setSelectedFruitsText('');
    setSelectedArticulosText("");
    setCantidad("1");
    setCurrentArticuloCosto("1");
  };

  const clearInputsAll = () => {
    setSelectedFruitsText("Selecione un cliente");
    setSelectedArticulosText("Selecione un Articulo");
    setCantidad("1");
    setCurrentArticuloCosto("1");
    setOrden([]);
    setCliente([]);
    setSolicitante("");
    setObservaciones("");
  };

  const sendOrder = async () => {
    const body = {
      user: user,
      order: orden,
      cliente: cliente,
      solicita: solicitante,
      total: total,
      observaciones: observaciones,
      fecha: selectedDate,
    };
    present({
      message: "Enviando orden...",
    });
    await csrfToken();
    try {
      const resp = await axios.post("/ordenar", body);

      if (resp.status === 200) {
        dismiss();
        setColorMegessa("success");
        setMessage("Orden Generada");
        setIsOpen(true);
        clearInputsAll();

        console.log(resp.data);
      }
    } catch (error) {
      console.log(error);

      dismiss();
      setColorMegessa("danger");
      setMessage("Algo salio mal");
      setIsOpen(true);
    }
  };

  const isButtonDisabled = !orden || !cliente || !solicitante;

  useEffect(() => {
    if (orden.length > 0) {
      const sum = orden.reduce(function (cnt, o) {
        return cnt + o.subtotal;
      }, 0);
      setTotal(sum);
      console.log("entro", sum);
    }
    if (loadig) handleSubmit();
  }, [handleSubmit, orden, loadig]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
            <IonTitle>Editar Orden #{id}</IonTitle>
          </IonButtons>
          <div style={{ marginRight: "8px" }} slot="end" id="div-total">
            Total: <b>${total}</b>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Tab 1</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem button={true} detail={false} id="select-fruits">
                <IonLabel color="success" position="stacked">
                  Cliente
                </IonLabel>
                <div id="selected-fruits">{selectedFruitsText}</div>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem button={true} detail={false} id="select-articulos">
                <IonLabel color="success" position="stacked">
                  Articulo
                </IonLabel>
                <div id="selected-articulo">{selectedArticulosText}</div>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel color="success">Fecha de entraga</IonLabel>
                <IonDatetimeButton datetime="datetime"></IonDatetimeButton>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel color="success" position="stacked">
                  Cantidad
                </IonLabel>
                <IonInput
                  type="number"
                  placeholder="000"
                  value={cantidad}
                  onIonChange={(event) => {
                    if (event.detail.value === undefined) return;
                    setCantidad(event.detail.value!.toString());
                  }}
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel color="success" position="stacked">
                  Vendido por {medida && medida.medida}
                </IonLabel>
                <IonSelect
                  value={value}
                  interface="popover"
                  placeholder="Cambiar tipo de venta"
                  onIonChange={(e) => handleChangeTipoVenta(e.detail.value)}
                  disabled={true}
                >
                  {medidas.map((o, i) => {
                    return (
                      <IonSelectOption key={i} value={o.id}>
                        {o.medida}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel color="success" position="stacked">
                  Costo
                </IonLabel>
                <IonInput
                  type="number"
                  placeholder="000"
                  value={currentArticuloCosto}
                  onIonChange={(event) => {
                    if (event.detail.value === undefined) return;
                    setCurrentArticuloCosto(event.detail.value!.toString());
                  }}
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonButton
                color="success"
                className="custom-button"
                size="large"
                expand="block"
                fill="solid"
                onClick={addItem}
              >
                <IonIcon icon={addCircleIcon} slot="end" />
                Agregar
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel color="success" position="stacked">
                  Solicita
                </IonLabel>
                <IonInput
                  placeholder="Nombre completo del solicitante"
                  value={solicitante}
                  onIonChange={(event) => {
                    if (event.detail.value === undefined) return;
                    setSolicitante(event.detail.value!.toString());
                  }}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonTextarea
                  value={observaciones}
                  onIonChange={(event) => {
                    if (event.detail.value === undefined) return;
                    setObservaciones(event.detail.value!.toString());
                  }}
                  placeholder="Escriba alguna observación"
                  autoGrow={true}
                ></IonTextarea>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid>
          <IonRow>
            <IonCol className="ionCol">Articulo</IonCol>
            <IonCol className="ionCol">Cantidad</IonCol>
            <IonCol className="ionCol">Costo</IonCol>
            <IonCol className="ionCol">Subtotal</IonCol>
            <IonCol className="ionCol">Acción</IonCol>
          </IonRow>
          {orden.map((o, index) => {
            return (
              <IonRow key={index}>
                <IonCol className="ionCol">{o.articulo.articulo}</IonCol>
                <IonCol className="ionCol">{o.cantidad}</IonCol>
                <IonCol className="ionCol">{o.precio}</IonCol>
                <IonCol className="ionCol">{parseFloat(o.precio!) * parseFloat(o.cantidad)}</IonCol>
                <IonCol className="ionCol">
                  <IonButton
                    onClick={() => removeFromOrder(index)}
                    color="danger"
                    size="small"
                  >
                    Eliminar
                  </IonButton>
                </IonCol>
              </IonRow>
            );
          })}
        </IonGrid>
        {false && (
          <IonGrid>
            <IonRow>
              <IonCol></IonCol>
              <IonCol></IonCol>
              <IonCol></IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel color="success" position="stacked">
                    Total
                  </IonLabel>
                  <IonInput
                    value={total}
                    type="number"
                    placeholder="000"
                  ></IonInput>
                </IonItem>
              </IonCol>
              <IonCol></IonCol>
            </IonRow>
          </IonGrid>
        )}
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton
            color="success"
            onClick={() => sendOrder()}
            disabled={true}
          >
            <IonIcon icon={sendIcon}></IonIcon>
          </IonFabButton>
        </IonFab>
      </IonContent>

      <IonModal trigger="select-fruits" ref={modal}>
        <AppTypeahead
          title="Clientes"
          items={clientes}
          selectedItems={selectedFruits}
          onSelectionCancel={() => modal.current?.dismiss()}
          onSelectionChange={fruitSelectionChanged}
        />
      </IonModal>
      <IonModal trigger="select-articulos" ref={modalArticulos}>
        <AppTypeaheadArticulos
          title="Articulos"
          items={[]}
          selectedItems={selectedArticulos}
          onSelectionCancel={() => modalArticulos.current?.dismiss()}
          onSelectionChange={articuloSelectionChanged}
        />
      </IonModal>
      <IonModal keepContentsMounted={true} ref={modalDate}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton strong={true} onClick={() => confirm()}>
                Cerrar
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonDatetime
          id="datetime"
          onIonChange={handleDateChange}
          locale="es-ES"
          presentation="date"
        ></IonDatetime>
      </IonModal>
      <IonToast
        isOpen={isOpen}
        duration={3000}
        onDidDismiss={() => setIsOpen(false)}
        color={colorMessage}
        message={message}
        class="custom-toast"
        buttons={[
          {
            text: "Cerrar",
            role: "cancel",
          },
        ]}
      ></IonToast>
    </IonPage>
  );
};

export default Tab3;
