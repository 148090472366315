import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRow,
} from "@ionic/react";

import React from "react";
import { useLocation } from "react-router-dom";
import {
  logOutOutline,
  logOutSharp,
  homeOutline,
  homeSharp,
  timeOutline,
  timeSharp,
} from "ionicons/icons";
import "./Menu.css";
import AuthService from "../services/auth.service";
import { useTranslation, withTranslation } from "react-i18next";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const Menu: React.FC = () => {
  const location = useLocation();
  const currentUser = AuthService.getCurrentUser();
  const { t } = useTranslation();

  const appPages: AppPage[] = [
    {
      title: t("Home"),
      url: "/home/tab1",
      iosIcon: homeOutline,
      mdIcon: homeSharp,
    },
    {
      title: t("Historial"),
      url: "/home/tab2",
      iosIcon: timeOutline,
      mdIcon: timeSharp,
    },
    {
      title: t("logout"),
      url: "/logout",
      iosIcon: logOutOutline,
      mdIcon: logOutSharp,
    },
  ];

  //const { t, i18n } = useTranslation();

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <img
            height={"50%"}
            width={"50%"}
            alt="Company Logo"
            src="/assets/images/logo.png"
          />
          <IonRow>
            <IonNote>{currentUser.email}</IonNote>
          </IonRow>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon slot="start" icon={appPage.iosIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default withTranslation()(Menu);
