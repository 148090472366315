import React, { useState, useEffect, useRef } from "react";
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonSearchbar,
  IonToolbar,
} from "@ionic/react";
import type { CheckboxCustomEvent } from "@ionic/react";
import type { Item } from "./types";
import axios from "../axios";

interface TypeaheadProps {
  items: Item[];
  selectedItems: string[];
  title?: string;
  onSelectionCancel?: () => void;
  onSelectionChange?: (items: string[]) => void;
}

function AppTypeahead(props: TypeaheadProps) {
  const [query, setQuery] = useState("");
  const [clientes, setClientes] = useState<Item[]>([]);
  const [workingSelectedValues, setWorkingSelectedValues] = useState<any[]>([]);

  const isChecked = (value: string) => {
    return (
      workingSelectedValues.find((item) => item.id === value) !== undefined
    );
  };

  const cancelChanges = () => {
    const { onSelectionCancel } = props;
    if (onSelectionCancel !== undefined) {
      onSelectionCancel();
    }
  };

  const confirmChanges = () => {
    const { onSelectionChange } = props;
    if (onSelectionChange !== undefined) {
      onSelectionChange(workingSelectedValues);
    }
  };

  const searchbarInput = (ev: any) => {
    setQuery(ev.target.value);
    //filterList(ev.target.value);
  };

  const checkboxChange = (ev: CheckboxCustomEvent) => {
    const { checked, value } = ev.detail;
    const cliente = clientes.find((clientes) => clientes.id === value)!;

    if (checked) {
      setWorkingSelectedValues([cliente]);
    } else {
      setWorkingSelectedValues(
        workingSelectedValues.filter((item) => item.id !== cliente.id)
      );
    }
  };

  const searchByTitle = async (title: string) => {
    const searchParams = new URLSearchParams({
      title,
    });

    const { data } = await axios.get(`/clientes?${searchParams}`);
    return data;
  };

  const inputRef = useRef<any>(null);

  useEffect(() => {
    //setTimeout(() => inputRef.current.setFocus(), 100);
    if (workingSelectedValues.length > 0) {
      const { onSelectionChange } = props;
      if (onSelectionChange !== undefined) {
        onSelectionChange(workingSelectedValues);
      }
    }
    if (query) {
      setClientes([]);

      searchByTitle(query)
        .then((resp) => {
          setClientes([...resp.data.clientes]);
        })
        .catch((error: []) => {
          console.log(error);
        });
    }
  }, [query, workingSelectedValues, props]);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={cancelChanges}>Cancelar</IonButton>
          </IonButtons>
          <IonTitle>{props.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={confirmChanges}>Seleccionar</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            ref={(ref) => (inputRef.current = ref)}
            placeholder="Buscar"
            onIonInput={searchbarInput}
          ></IonSearchbar>
        </IonToolbar>
      </IonHeader>

      <IonContent color="light" class="ion-padding">
        <IonList id="modal-list" inset={true}>
          {clientes.map((item) => {
            return (
              <IonItem key={item.id}>
                <IonLabel>{item.nombre}</IonLabel>
                <IonCheckbox
                  value={item.id}
                  name={item.nombre}
                  checked={isChecked(item.id)}
                  onIonChange={checkboxChange}
                ></IonCheckbox>
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>
    </>
  );
}
export default AppTypeahead;
