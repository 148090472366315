import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonGrid,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonIcon,
  IonSpinner,
  IonRefresher,
  IonRefresherContent,
  IonItemSliding,
  IonItem,
  IonLabel,
  IonItemOptions,
  IonItemOption,
  IonActionSheet,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  useIonLoading,
  IonToast,
  IonSearchbar,
} from "@ionic/react";
import {
  refresh as refreshIcon,
  create as createIcon,
  trash as trashIcon,
  print as printIcon,
} from "ionicons/icons";
import { RefresherEventDetail, OverlayEventDetail } from "@ionic/core";
import axios from "../axios";
import "./Tab2.css";
import Moment from "moment";
import "moment/locale/es";

const Tab2: React.FC = () => {
  const userString: string | null = localStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : null;
  const [isLoading, setIsLoading] = useState(true);
  const [ordenes, setOrdenes] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [present, dismiss] = useIonLoading();
  const [message, setMessage] = useState<string>();
  const [colorMessage, setColorMegessa] = useState<string>();
  const [isOpen, setIsOpen] = useState(false);

  const loadSaldo = async () => {
    const searchParams = new URLSearchParams({
      page: page + '',
      vendedor: user.id,
    });

    try {
      const { data } = await axios.get(`/ordenes?${searchParams}`);
      setIsLoading(false);
      setPage(page + 1);
      return data.data.ordenes.data;
    } catch (error) {
      // Handle error (e.g., show an error message or log the error)
      console.error("Error while fetching data:", error);
      setIsLoading(false);
      return null; // Or return an empty array/object or an appropriate default value
    }
  };

  const onRefreshClick = () => {
    setIsLoading(true);
    loadSaldo().then((response) => setOrdenes(response.data.ordenes));
  };

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    console.log("Begin async operation");
    event.detail.complete();
    setIsLoading(true);
    loadSaldo().then((response) => setOrdenes(response.data.ordenes));
  }

  const logResult = async (result: OverlayEventDetail) => {
    if (result.role === "destructive") {
      const orderId = result.data.id;
      try {
        const resp = await axios.delete("/order/" + orderId);

        if (resp.status === 200) {
          const updatedData = ordenes.filter((item) => item.id !== orderId);
          setOrdenes(updatedData);
          console.log(resp);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getMoreItems = async () => {
    const searchParams = new URLSearchParams({
      page: page + '',
      vendedor: user.id,
    });
    const { data } = await axios.get(`/ordenes?${searchParams}`);
    setPage(page + 1);
    setOrdenes([...ordenes, ...data.data.ordenes.data]);
  };

  const reprintTicket = async (orderId: number) => {
    console.log('reprintTicket');
    const params = new URLSearchParams({
      orderId: orderId + '',
    });
    try {
      const { data } = await axios.get(`/reprint?${params}`);
      console.log('reprintTicket', data.status);
      dismiss();
      setColorMegessa("success");
      setMessage("Su orden será impresa en unos minutos");
      setIsOpen(true);
    } catch (error) {
      console.log(error);

      dismiss();
      setColorMegessa("danger");
      setMessage("Algo salio mal");
      setIsOpen(true);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      const response = await loadSaldo();
      setOrdenes(response);
    };

    fetchData();
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />

            <IonTitle>Generar nueva orden</IonTitle>
            <IonButton slot="end" fill="clear" onClick={onRefreshClick}>
              <IonIcon icon={refreshIcon} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar></IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Tab 2</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonRefresher
            slot="fixed"
            onIonRefresh={doRefresh}
            pullFactor={0.5}
            pullMin={100}
            pullMax={200}
          >
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <IonGrid fixed={true}>
            {isLoading ? (
              <div className="spin">
                <IonSpinner></IonSpinner>
              </div>
            ) : (
              <>
                <IonRow>
                  <IonCol size="1">#</IonCol>
                  <IonCol size="2">Vendedor</IonCol>
                  <IonCol size="2">Cliente</IonCol>
                  <IonCol size="2">Total</IonCol>
                  <IonCol size="2">Captura</IonCol>
                  <IonCol size="3">Entrega</IonCol>
                </IonRow>
                {ordenes.map((input: any, index: any) => (
                  <IonRow key={index}>
                    <IonCol size="1">{input.id}</IonCol>
                    <IonCol size="2">{input.vendedor.name}</IonCol>
                    <IonCol size="2">{input.cliente.nombre}</IonCol>
                    <IonCol size="2">
                      {new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      }).format(input.total)}
                    </IonCol>
                    <IonCol size="2">
                      {Moment(input.created_at).format("MMMM DD, YYYY hh:mm A")}
                    </IonCol>
                    <IonCol size="3">
                      <IonItemSliding>
                        <IonItem>
                          <IonLabel>
                            {input.fecha_entrega
                              ? Moment(input.fecha).format("MMMM DD, YYYY")
                              : ""}
                          </IonLabel>
                        </IonItem>

                        <IonItemOptions>
                          <IonItemOption
                            color="success"
                            onClick={(event) => reprintTicket(input.id)}
                          >
                            <IonIcon
                              slot="icon-only"
                              icon={printIcon}
                            ></IonIcon>
                          </IonItemOption>
                          <IonItemOption
                            routerLink={"/home/order/" + input.id + "/edit"}
                          >
                            <IonIcon
                              slot="icon-only"
                              icon={createIcon}
                            ></IonIcon>
                          </IonItemOption>

                          <IonItemOption
                            color="danger"
                            id={"open-action-sheet-" + input.id}
                          >
                            <IonIcon
                              slot="icon-only"
                              icon={trashIcon}
                            ></IonIcon>
                          </IonItemOption>
                          <IonActionSheet
                            trigger={"open-action-sheet-" + input.id}
                            className="my-custom-class"
                            header={"Orden #" + input.id}
                            subHeader="¿Eliminar esta Orden?"
                            buttons={[
                              {
                                text: "Si",
                                role: "destructive",
                                data: {
                                  action: "delete",
                                  id: input.id,
                                },
                              },
                              {
                                text: "Cancelar",
                                role: "cancel",
                                data: {
                                  action: "cancel",
                                },
                              },
                            ]}
                            onDidDismiss={({ detail }) => logResult(detail)}
                          ></IonActionSheet>
                        </IonItemOptions>
                      </IonItemSliding>
                    </IonCol>
                  </IonRow>
                ))}
              </>
            )}
          </IonGrid>
          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              getMoreItems();
              setTimeout(() => ev.target.complete(), 500);
            }}
          >
            <IonInfiniteScrollContent></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonContent>
      </IonContent>
      <IonToast
        isOpen={isOpen}
        duration={3000}
        onDidDismiss={() => setIsOpen(false)}
        color={colorMessage}
        message={message}
        class="custom-toast"
        buttons={[
          {
            text: "Cerrar",
            role: "cancel",
          },
        ]}
      ></IonToast>
    </IonPage>
  );
};

export default Tab2;
