import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonBackButton,
  IonTitle  
} from '@ionic/react';
import React from 'react';
import ExploreContainer from '../components/ExploreContainer';


const NotFoundPage: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
          </IonButtons>
            <IonTitle>Página no encontrada</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
      <ExploreContainer name="Página no encontrada" />
      </IonContent>
    </IonPage>
  );
};

export default NotFoundPage;
