
 function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("authToken");
}

function  getCurrentUser() {
  if(localStorage.getItem('user')){
    return JSON.parse(localStorage.getItem('user'));
  }else{
    return false;
  }
}

function loggedIn(){
  if(localStorage.getItem('user')){
    return true;
  }else{
    return false;
  }
}

function isDarkModeEnable(){
  if(localStorage.getItem('darkmode')){
    if(JSON.parse(localStorage.getItem('darkmode')) === true){
      return JSON.parse(localStorage.getItem('darkmode'));
    }else{
      return false;
    }
    
  }else{
    return false;
  }
}


const AuthService = {
  logout,
  getCurrentUser,
  loggedIn,
  isDarkModeEnable,
};

export default AuthService;
